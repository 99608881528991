import React from 'react'

const pagesRoutes = [
    {
        path: '/pages/orders',
        component: React.lazy(() =>
            import('./orders/Open/Cities/OrderListOpen')
        ),
    },
    {
        path: '/pages/orders-afgerond',
        component: React.lazy(() =>
            import('./orders/Finished/OrderListFinished')
        ),
    },
    {
        path: '/pages/dagtotalen',
        component: React.lazy(() => import('./dashboard/Analytics')),
    },
]

export default pagesRoutes
